$canvasColor: #fff;
$defaultTextColor: #201d1d;
$invertedTextColor: $canvasColor;
$secondaryColor: #201d1d;
$borderRadius: 4px;

@font-face {
  font-family: Rubik;
  src: url(@fonts/Rubik-Regular.ttf);
}

@font-face {
  font-family: Agency_FB;
  src: url(@fonts/Agency_FB.ttf);
}

@font-face {
  font-family: Agency_FB_Bold;
  src: url(@fonts/Agency_FB_Bold.ttf);
}

@font-face {
  font-family: SourceCodePro-Regular;
  src: url(@fonts/SourceCodePro-Regular.otf);
}

@font-face {
  font-family: SourceCodePro-Bold;
  src: url(@fonts/SourceCodePro-Bold.otf);
}

html, body {
  max-width: 100%;
  max-height: 100%;
  margin: 0;
  padding: 0;
  font-family: Rubik, sans-serif;
  background-color: $canvasColor;
  color: $secondaryColor;
}

h1 {
  text-transform: uppercase;
}

h1, h2, h3, h4, h5, h6, label {
  font-family: Agency_FB_Bold;
  letter-spacing: .2em;
  text-align: center;
}

.slash {
  letter-spacing: normal;
}

.attention {
  padding: 2em;
  font-size: 1.5em;
  display: block;
  max-width: 700px;
  margin: 0 auto;
}
strong {
  font-size: 1.1em;
}
header, footer {
  background-image: url(@images/layout/headerbg-o.png);
  margin: 0;
  padding: 0;
  background-attachment: fixed;
  background-size: cover;
  width: 100%;
}

header {
  background-position: center top;
}

footer {
  background-position: center bottom;
}

header .logo {
  display: block;
  margin: 0 auto;
  padding-top: 4em;
  width: 30%;
  min-width: 300px;
}

footer .logo {
  height: 64px;
}

.rect{
  background-color: #fff;
  width: 100%;
  max-width: 1010px;
  height: 100px;
  
  &.top {
    margin: 50px auto 0 auto;
    border-radius: $borderRadius $borderRadius 0 0;
  }
  &.bottom {
    margin: 0 auto 100px auto;
    border-radius: 0 0 $borderRadius $borderRadius;
  }
}

.display {
  display: flex;
  width: 100%;
  justify-content: center;
  flex-wrap: wrap;
}

#techlogos {
  background-image: url(@images/logos/logobg-2.png);
  background-size: contain;
  padding: 60px 0;
}

.techLogos {
  .figure{
    background-color: rgba(255,255,255, .5);
  }
}

figure {
  width: 100px;
  vertical-align: middle;
  text-align: center;
  
}

.figure-image-wrapper {
  width: 100px;
  height: 100px;
  vertical-align: middle;
  display: table-cell;
  vertical-align: middle;
}

.figure-image-wrapper img {
  max-width: 100%;
  max-height: 100%;
  display: block;
  margin: 0 auto;
}

ul.education {
  font-family: Agency_FB_Bold;
  letter-spacing: .2em;
  text-align: center;
  padding: 0;
  margin: 0;
  list-style-type: none;
  font-size: 1.17em;
  text-transform: uppercase;
  li{
    margin: 1em;
  }
}

.contact {
  display: block;
  margin: 0 auto;
  text-align: center;
  &.bottom {
    padding: 4em;
  }
}

.contact a {
  display: inline-block;
  margin: 1em;
  width: 64px;
  height: 64px;
  transition: background-image 1s;
  background-color: transparent;
  background-size: contain;
  background-repeat: no-repeat;
}

.contact .logo {
  display: inline-block;
  height: 64px;
  width: 270px;
  background-image: url(@images/logos/braydonpowell.svg);
}

.contact .logo:hover {
  background-image: url(@images/logos/braydonpowellhover.svg);
}

.email {
  background-image: url(@images/icons/email.svg);
}

.email:hover {
  background-image: url(@images/icons/emailhover.svg);
}

.github {
  background-image: url(@images/icons/github.svg);
}

.github:hover {
  background-image: url(@images/icons/githubhover.svg);
}

section {
  max-width: 1000px;
  margin: 0 auto;
  padding: 5px;
  border-right: 2px solid $secondaryColor;
  border-left: 2px solid $secondaryColor;
}

section.alt {
  max-width: 100%;
  background-position: center;
  background-image: url(@images/layout/headerbg-o.png);
  background-attachment: fixed;
  background-size: cover;
  background-color: $secondaryColor;
  padding: 0;
  border-left: 0;
  border-right: 0;
  color: $invertedTextColor;
  border-color: $canvasColor;
}

section.alt .container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 5px;
}

.flex { 
  display: flex;
  flex-wrap: wrap;
  .column {
    max-width: 250px;
  }
}


.spotlighterShade {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  background-color: rgba(0, 0, 0, 0.8);
}

.spotlighterContent {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  background-color: #fff;
  border-radius: $borderRadius;
  padding: 5px;
  max-width: 80vw;
  max-height: 80vh;
  overflow: auto;
  transition: height 1s, width 1s;
}

.spotlighterContent .close {
  float: right;
  cursor: pointer;
}

img.example {
  cursor: pointer;
}

img.loading {
  display: block;
  margin: 0 auto;
}

.contactContainer {
  max-width: 26.1em;
  min-width: 300px;
  transition: height 1s, width 1s;
}

.inputContainer, .textAreaContainer {
  padding: 5px;
  min-width: 12em;
  vertical-align: top;
  margin-bottom: 5px;
  display: inline-block;
}

.textAreaContainer {
  width: 96%;
  max-width: 100%;
}

label {
  display: block;
  text-align: left;
}

input, textarea {
  border: 0;
  border: 2px solid;
  border-radius: $borderRadius;
  border-color: gray;
  height: 2em;
  padding-left: 5px;
  outline: none;
  transition: background-color 1s, color 1s;
  width: 96%;
  color: $secondaryColor;
  font-family: Rubik, sans-serif;
  &:focus{
    border-color: black;
  }
  &.error{
    color: $secondaryColor;
    border-bottom-color: red;
  }
  &.success{
    color: $secondaryColor;
  }
}

textarea {
  height: 5em;
}

.formFeedback {
  min-height: 1.3em;
  display: block;
  text-align: center;
}

input[type="submit"], button {
  background-color: #fff;
  font-size: 1em;
  border: 2px $secondaryColor solid;
  width: 10em;
  display: block;
  margin: 0 auto;
  transition: background-color 1s, color 1s;
  cursor: pointer;
  color: $secondaryColor;
  border-radius: $borderRadius;
}

input[type="submit"]:hover, button:hover {
  background-color: #ababab;
}

span.error, span.success {
  text-align: center;
  display: inherit;
}

.error {
  color: red;
}

.success {
  color: green;
}

.emphasis {
  margin: 1em;
  font-size: xx-large;
}

.g-recaptcha {
  width: 304px;
  display: block;
  margin: 0 auto;
}

li{
  margin: 8px;
}

@media (max-width: 950px) {
  ul {
    max-width: 100%;
    vertical-align: top;
  }
  figure {
    width: 200px;
    vertical-align: middle;
  }
  .figure-image-wrapper {
    width: 200px;
    height: 200px;
  }
  .g-recaptcha {
    width: initial;
    transform: scale(0.8);
    transform-origin: 0 0;
  }
  .textAreaContainer {
    width: 70%;
  }
  input[type="submit"], button {
    margin: initial;
  }
}

/*# sourceMappingURL=portfolio.css.map */
